import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
import marked from 'marked'
import CommonHero from '../components/commonhero/commonhero.js'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  // data.markdownRemark holds our post data
  const { sanityPost } = data // data.markdownRemark holds our post data
  const { body } = sanityPost
  return (
    <Layout
      head={{
        title: `${sanityPost.title}`,
        meta: { title: sanityPost.title, description: sanityPost.shortdesc },
      }}
      pageTitle={sanityPost.title}
    >
      <CommonHero pageTitle={sanityPost.title} />
      <div className="blogpost-wrap">
        <div className="container py-5">
          <div className="blog-post-container">
            <div className="blog-post">
              <div
                className="blog-post-content col-md-10 mx-auto"
                dangerouslySetInnerHTML={{ __html: marked(body) }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query singleNewsQuery($id: String!) {
    sanityPost(id: { eq: $id }) {
      id
      title
      shortdesc
      slug {
        current
      }
      body
      _createdAt
      mainImage {
        asset {
          url
        }
      }
      author {
        name
        image {
          asset {
            url
          }
        }
      }
    }
  }
`
